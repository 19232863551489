import Logo from "assets/img/logo.png"
import Wwd1 from "assets/img/watwijdoen/1.jpeg"
import Wwd2 from "assets/img/watwijdoen/2.png"
import Wwd3 from "assets/img/watwijdoen/3.png"
import Footer from "components/footer/Footer"
import Nav from "components/nav/Nav"
import WrapperBlock from "components/wrapper/WrapperBlock"
import WrapperPage from "components/wrapper/WrapperPage"
import { ApproachInterface } from "libs/interfaces"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Banner from "utils/elements/Banner"

const Approach = () => {
  const { t } = useTranslation(["landing"])

  const [approach] = useState<ApproachInterface[]>([
    { title: t("approach_list_1_title"), text: t("approach_list_1_text") },
    { title: t("approach_list_2_title"), text: t("approach_list_2_text") },
    { title: t("approach_list_3_title"), text: t("approach_list_3_text") },
    { title: t("approach_list_4_title"), text: t("approach_list_4_text") },
    { title: t("approach_list_5_title"), text: t("approach_list_5_text") }
  ])

  return (
    <>
      <Nav />
      <WrapperPage>
        <Banner title={t("approach_title")} text={t("approach_text")} />
        <div className="w-full bg-white">
          <WrapperBlock>
            <div className="w-full">
              <div className="mb-40 flex w-full justify-center">
                <img src={Logo} alt="" className="h-80" />
              </div>
              <div className="mb-20 w-full text-center text-24 font-bold text-blue-dark sm:text-30">
                {t("approach_title")}
              </div>
              <div className="mb-60 grid w-full grid-cols-1 gap-40 lg:grid-cols-2">
                <div className="grid w-full grid-cols-1 gap-30">
                  <div className="w-full text-14 font-semibold text-blue-dark sm:text-16 md:text-18">
                    {t("approach_left_1")}
                  </div>
                  <div className="w-full text-14 font-semibold text-blue-dark sm:text-16 md:text-18">
                    {t("approach_left_2")}
                  </div>
                </div>
                <div className="w-full text-14 font-semibold text-blue-dark sm:text-16 md:text-18">
                  {t("approach_right_1")}
                </div>
              </div>
              <div className="mb-80 grid w-full grid-cols-1 gap-40 sm:mb-100 sm:gap-60 md:mb-[150px] md:grid-cols-3">
                <div className="aspect-w-1 aspect-h-1 w-full">
                  <img
                    src={Wwd1}
                    alt=""
                    className="w-full rounded-[20px] object-cover"
                  />
                </div>
                <div className="aspect-w-1 aspect-h-1 w-full">
                  <img
                    src={Wwd2}
                    alt=""
                    className="w-full rounded-[20px] object-cover"
                  />
                </div>
                <div className="aspect-w-1 aspect-h-1 w-full">
                  <img
                    src={Wwd3}
                    alt=""
                    className="w-full rounded-[20px] object-cover"
                  />
                </div>
              </div>
              <div className="mb-40 w-full text-center text-24 font-bold text-blue-dark sm:text-30">
                {t("approach_list_title")}
              </div>
              <div className="grid w-full grid-cols-1 gap-50">
                {approach.map((step: ApproachInterface, key: number) => (
                  <div
                    className="grid w-full grid-cols-[auto,1fr] gap-20 sm:gap-40"
                    key={key}
                  >
                    <div className="mt-[-24px] flex text-[54px] font-bold text-grey-med">
                      {key + 1}
                    </div>
                    <div className="grid w-full grid-cols-1 gap-10">
                      <div className="w-full text-16 font-black text-blue-dark sm:text-18 md:text-20">
                        {step.title}
                      </div>
                      <div className="w-full text-14 text-blue-dark sm:text-16 md:text-18">
                        {step.text}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </WrapperBlock>
        </div>
      </WrapperPage>
      <Footer />
    </>
  )
}

export default Approach
