import { ReactComponent as Triangle } from "assets/img/triangle.svg"
import Wrapper from "components/wrapper/Wrapper"
import React from "react"

const Banner = ({ title, text }: { title: string; text?: string }) => {
  return (
    <>
      <div className="relative mb-0 w-full sm:mb-60">
        <Triangle className="absolute top-0 right-0 z-10 hidden h-[400px] w-[400px] translate-x-[50%] translate-y-[-40%] rotate-[12deg] transform md:flex" />
        <Triangle className="absolute bottom-0 left-0 z-10 hidden h-[500px] w-[500px] translate-x-[-75%] translate-y-[20%] rotate-[0deg] transform md:flex" />
        <div className="relative w-full overflow-hidden bg-gradient-to-tr from-blue-light to-blue p-60 sm:p-80 md:p-100">
          <div className="grid w-full grid-cols-1 gap-20">
            <div className="md:48 w-full text-center text-24 font-bold text-blue-dark sm:text-32">
              {title}
            </div>
            <Wrapper open={text ? true : false}>
              <div className="flex w-full justify-center">
                <div className="w-[700px] max-w-full text-center text-14 text-blue-dark sm:text-16 md:text-18">
                  {text}
                </div>
              </div>
            </Wrapper>
          </div>
        </div>
      </div>
    </>
  )
}

export default Banner
