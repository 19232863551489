import React from "react"

const FooterInput = ({
  title,
  value,
  handler
}: {
  title: string
  value: string
  handler: any
}) => {
  return (
    <>
      <div className="w-full bg-blue-50">
        <input
          type="text"
          className="flex h-50 w-full items-center px-20 text-white"
          placeholder={title}
          value={value}
          onChange={(e) => handler(e.target.value)}
        />
      </div>
    </>
  )
}

export default FooterInput
