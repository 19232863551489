import React from "react"

const FooterArea = ({
  title,
  value,
  handler
}: {
  title: string
  value: string
  handler: any
}) => {
  return (
    <>
      <div className="w-full bg-blue-50">
        <textarea
          className="flex h-[170px] w-full resize-none items-center py-14 px-20 text-white"
          placeholder={title}
          value={value}
          onChange={(e) => handler(e.target.value)}
        />
      </div>
    </>
  )
}

export default FooterArea
