import Apples from "assets/img/cases/apples.png"
import Footer from "components/footer/Footer"
import Nav from "components/nav/Nav"
import WrapperBlock from "components/wrapper/WrapperBlock"
import WrapperPage from "components/wrapper/WrapperPage"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Banner from "utils/elements/Banner"

const Cases = () => {
  const { t } = useTranslation(["landing"])

  const [open, setOpen] = useState(false)

  return (
    <>
      <Nav />
      <WrapperPage>
        <Banner title={t("cases_title")} />
        <div className="w-full bg-white">
          <WrapperBlock>
            <div className="w-full">
              <div className="grid w-full grid-cols-1 gap-60 sm:gap-80 lg:grid-cols-2">
                <div className="w-full">
                  <img
                    src={Apples}
                    alt=""
                    className="h-[400px] w-full rounded-[20px] object-cover"
                  />
                </div>
                <div className="w-full">
                  <div className="md:text-36 mb-20 w-full text-24 font-bold text-blue-dark sm:text-32">
                    {t("cases_1_title")}
                  </div>
                  <div
                    className={`mb-30 w-full text-14 text-blue-dark sm:text-16 ${
                      open ? "" : "line-clamp-6"
                    }`}
                  >
                    {t("cases_1_text")}
                  </div>
                  <div className="flex w-full justify-start">
                    <button
                      onClick={() => setOpen(!open)}
                      className="h-[56px] rounded-[10px] bg-purple px-40 text-14 font-bold text-white sm:text-16"
                    >
                      {open ? t("cases_less") : t("cases_more")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </WrapperBlock>
        </div>
      </WrapperPage>
      <Footer />
    </>
  )
}

export default Cases
