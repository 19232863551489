import React from "react"

const WrapperPage = ({ children }: { children: any }) => {
  return (
    <>
      <div className="mt-80 w-full bg-white">{children}</div>
    </>
  )
}

export default WrapperPage
