export const SERVER = process.env.REACT_APP_SERVER

export const URL = {
  HOME: "/",
  ABOUT: "/about",
  APPROACH: "/approach",
  CASES: "/cases",
  CONTACT: "/contact",
  SOlUTIONS: "/solutions"
}

export const REQ = {
  CALL: SERVER + "/call"
}

export const SOCIAL = {
  LINKEDIN: "https://www.linkedin.com/company/yanalytics",
  INSTAGRAM: "https://www.instagram.com/yanalytics/",
  MEREL: "https://www.linkedin.com/in/merelypinga",
  PAUL: "https://www.linkedin.com/in/paul-ypinga",
  COOKIE: "/documents/Cookie statement.pdf",
  PRIVACY: "/documents/Privacy verklaring.pdf"
}

export const DATE = {
  DATETIME: "MMM D YYYY, HH:mm",
  SHORT: "MMM D, YYYY"
}

export const EMAIL = {
  username: "welcome@y-analytics.nl",
  password: "0CCA9B4D140EA8C8F21D15A6CF5E27510654",
  server: "smtp.elasticemail.com",
  port: "2525",
  api: "C28902F08A308E29A87740A9EE2B4FECF3DB20D2A4FAE3FABA86407EAE751944AC0D8EEDA6834585F0202C8B0ADBB3A4"
}
