import Logo from "assets/img/logo.png"
import Pattern from "assets/img/pattern.png"
import Footer from "components/footer/Footer"
import Nav from "components/nav/Nav"
import WrapperBlock from "components/wrapper/WrapperBlock"
import WrapperPage from "components/wrapper/WrapperPage"
import { URL } from "libs/constants"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import BannerSlide from "utils/elements/BannerSlide"

const Home = () => {
  const { t } = useTranslation(["landing"])

  return (
    <>
      <Nav />
      <WrapperPage>
        <BannerSlide titles={[t("home_slide_1"), t("home_slide_2")]} />
        <div className="w-full bg-white">
          <WrapperBlock>
            <div className="w-full">
              <div className="mb-10 w-full text-center text-24 font-bold text-blue-dark sm:text-30">
                {t("home_data_title")}
              </div>
              <div className="mb-40 w-full text-center text-16 font-semibold text-blue-dark sm:text-18 md:text-20">
                {t("home_data_sub")}
              </div>
              <div className="mb-40 flex w-full justify-center">
                <img src={Logo} alt="" className="h-80" />
              </div>
              <div className="mb-10 w-full text-center text-14 text-blue-dark sm:text-16 md:text-18">
                {t("home_data_text")}
              </div>
              <div className="w-full text-center text-14 font-bold text-blue-dark sm:text-16 md:text-18">
                {t("home_data_bold")}
              </div>
            </div>
          </WrapperBlock>
        </div>
        <div className="relative w-full bg-blue">
          <img
            src={Pattern}
            alt=""
            className="absolute top-0 left-0 h-full w-full object-cover opacity-[.03]"
          />
          <WrapperBlock>
            <div className="mb-14 w-full text-center text-24 font-bold text-blue-dark sm:text-32 md:text-48">
              {t("home_banner_title")}
            </div>
            <div className="mb-40 w-full text-center text-16 font-semibold text-blue-dark sm:text-18 md:text-20">
              {t("home_banner_text")}
            </div>
            <div className="flex w-full justify-center">
              <Link
                to={URL.APPROACH}
                className="font-sembold flex h-48 w-[240px] max-w-full items-center justify-center rounded-full bg-purple text-14 text-white sm:text-16 md:text-18"
              >
                {t("home_banner_button")}
              </Link>
            </div>
          </WrapperBlock>
        </div>
      </WrapperPage>
      <Footer />
    </>
  )
}

export default Home
