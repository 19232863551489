import Logo from "assets/img/logo.png"
import Footer from "components/footer/Footer"
import Nav from "components/nav/Nav"
import WrapperBlock from "components/wrapper/WrapperBlock"
import WrapperPage from "components/wrapper/WrapperPage"
import React from "react"
import { useTranslation } from "react-i18next"
import Banner from "utils/elements/Banner"

const Contact = () => {
  const { t } = useTranslation(["landing"])

  return (
    <>
      <Nav />
      <WrapperPage>
        <Banner title={t("contact")} />
        <div className="w-full bg-white">
          <WrapperBlock>
            <div className="w-full">
              <div className="mb-40 flex w-full justify-center">
                <img src={Logo} alt="" className="h-80" />
              </div>
            </div>
          </WrapperBlock>
        </div>
      </WrapperPage>
      <Footer />
    </>
  )
}

export default Contact
