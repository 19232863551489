import Merel from "assets/img/overons/merel.png"
import Paul from "assets/img/overons/paul.png"
import Footer from "components/footer/Footer"
import Nav from "components/nav/Nav"
import WrapperBlock from "components/wrapper/WrapperBlock"
import WrapperPage from "components/wrapper/WrapperPage"
import { SOCIAL } from "libs/constants"
import { openLink } from "libs/functions"
import { TeamInterface } from "libs/interfaces"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Banner from "utils/elements/Banner"

const About = () => {
  const { t } = useTranslation(["landing"])

  const [team] = useState<TeamInterface[]>([
    {
      image: Merel,
      name: t("about_merel"),
      description: t("about_merel_text"),
      link: SOCIAL.MEREL
    },
    {
      image: Paul,
      name: t("about_paul"),
      description: t("about_paul_text"),
      link: SOCIAL.PAUL
    }
  ])

  return (
    <>
      <Nav />
      <WrapperPage>
        <Banner title={t("about_title")} />
        <div className="w-full bg-white">
          <WrapperBlock>
            <div className="w-full">
              <div className="mb-60 w-full text-center text-18 font-bold italic text-blue-dark sm:mb-80 sm:text-20">
                {t("about_sub")}
              </div>
              <div className="grid w-full grid-cols-1 gap-80 sm:gap-100">
                {team.map((person: TeamInterface, key: number) => (
                  <div
                    className={`grid w-full grid-cols-1 items-center gap-60 sm:gap-80 ${
                      key % 2 === 0
                        ? "lg:grid-cols-[auto,1fr]"
                        : "lg:grid-cols-[1fr,auto]"
                    }`}
                    key={key}
                  >
                    <div
                      className={`aspect-w-1 aspect-h-1 w-[400px] max-w-full ${
                        key % 2 === 0 ? "" : "row-start-1 lg:col-start-2"
                      }`}
                    >
                      <img
                        src={person.image}
                        alt=""
                        className="w-full rounded-[20px] object-cover"
                      />
                    </div>
                    <div className="flex w-full items-center">
                      <div className="grid w-full grid-cols-1 gap-20">
                        <div className="md:text-36 w-full text-24 font-bold text-blue-dark sm:text-32">
                          {person.name}
                        </div>
                        <div className="w-full text-14 text-blue-dark sm:text-16">
                          {person.description}
                        </div>
                        <button
                          onClick={(e) => openLink(e, person.link)}
                          className="w-full text-left text-14 text-blue-dark underline sm:text-16"
                        >
                          LinkedIn {person.name}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </WrapperBlock>
        </div>
      </WrapperPage>
      <Footer />
    </>
  )
}

export default About
