import App from "App"
import { PropsProvider } from "contexts/PropsContext"
import { init } from "i18next"
import React from "react"
import { CookiesProvider } from "react-cookie"
import ReactDOM from "react-dom"
import { ToastContainer } from "react-toastify"

import reportWebVitals from "./reportWebVitals"
import "react-toastify/dist/ReactToastify.css"
import "assets/styles/base/base.css"
import "assets/styles/base/global.css"
import "assets/styles/base/index.css"
import "assets/styles/base/theme.css"
import "assets/styles/css/fade.css"
import "assets/styles/css/fonts.css"

const main = async () => {
  init({
    fallbackLng: "nl",
    lng: "nl",
    debug: true,

    interpolation: {
      escapeValue: false
    },

    ns: ["landing"],
    defaultNS: "landing"
  })

  ReactDOM.render(
    <>
      <CookiesProvider>
        <PropsProvider>
          <App />
        </PropsProvider>
      </CookiesProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className="z-50"
      />
    </>,
    document.getElementById("app")
  )
}

reportWebVitals()
main()
