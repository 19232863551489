import { useProps } from "contexts/PropsContext"
import { t } from "i18next"
import { URL } from "libs/constants"
import { LinkInterface } from "libs/interfaces"
import React, { useState } from "react"
import { Link } from "react-router-dom"

const NavOpen = () => {
  const [links] = useState<LinkInterface[]>([
    { title: t("nav_home"), link: URL.HOME },
    { title: t("nav_approach"), link: URL.APPROACH },
    { title: t("nav_solution"), link: URL.SOlUTIONS },
    { title: t("nav_about"), link: URL.ABOUT },
    { title: t("nav_cases"), link: URL.CASES },
    { title: t("nav_contact"), link: URL.CONTACT }
  ])

  const { path } = useProps()

  return (
    <>
      <div className="fixed top-80 left-0 bottom-0 z-20 flex w-screen items-center justify-center bg-white p-20 sm:p-40">
        <div className="grid max-h-[100%] w-full grid-cols-1 gap-10 overflow-scroll">
          {links.map((link: LinkInterface, key: number) => (
            <Link
              to={link.link}
              key={key}
              className={`outline-none text-16 uppercase text-blue-dark transition-all duration-300 hover:font-black sm:text-18 md:text-20 ${
                path === link.link ? "font-black" : ""
              }`}
            >
              {link.title}
            </Link>
          ))}
        </div>
      </div>
    </>
  )
}

export default NavOpen
