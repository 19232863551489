import Logo from "assets/img/logo.png"
import ImgAppr1 from "assets/img/oplossingen/appr1.png"
import ImgAppr2 from "assets/img/oplossingen/appr2.png"
import ImgAppr3 from "assets/img/oplossingen/appr3.png"
import ImgAppr4 from "assets/img/oplossingen/appr4.png"
import ImgAppr5 from "assets/img/oplossingen/appr5.png"
import ImgAppr6 from "assets/img/oplossingen/appr6.png"
import ImgAppr7 from "assets/img/oplossingen/appr7.png"
import ImgOppr1 from "assets/img/oplossingen/oppr1.png"
import ImgOppr2 from "assets/img/oplossingen/oppr2.png"
import ImgOppr3 from "assets/img/oplossingen/oppr3.png"
import ImgOppr4 from "assets/img/oplossingen/oppr4.png"
import ImgOppr5 from "assets/img/oplossingen/oppr5.png"
import Footer from "components/footer/Footer"
import Nav from "components/nav/Nav"
import SolutionsItem from "components/solutions/SolutionsItem"
import WrapperBlock from "components/wrapper/WrapperBlock"
import WrapperPage from "components/wrapper/WrapperPage"
import { SolutionType } from "libs/enums"
import { SolutionInterface } from "libs/interfaces"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Banner from "utils/elements/Banner"

const Solutions = () => {
  const { t } = useTranslation(["landing"])

  const [type, setType] = useState(SolutionType.Approach)

  const [listAppr] = useState<SolutionInterface[]>([
    {
      title: t("solutions_appr_1_title"),
      text: t("solutions_appr_1_text"),
      image: ImgAppr1
    },
    {
      title: t("solutions_appr_2_title"),
      text: t("solutions_appr_2_text"),
      image: ImgAppr2
    },
    {
      title: t("solutions_appr_3_title"),
      text: t("solutions_appr_3_text"),
      image: ImgAppr3
    },
    {
      title: t("solutions_appr_4_title"),
      text: t("solutions_appr_4_text"),
      image: ImgAppr4
    },
    {
      title: t("solutions_appr_5_title"),
      text: t("solutions_appr_5_text"),
      image: ImgAppr5
    },
    {
      title: t("solutions_appr_6_title"),
      text: t("solutions_appr_6_text"),
      image: ImgAppr6
    },
    {
      title: t("solutions_appr_7_title"),
      text: t("solutions_appr_7_text"),
      image: ImgAppr7
    }
  ])

  const [listOppr] = useState<SolutionInterface[]>([
    {
      title: t("solutions_oppr_1_title"),
      text: t("solutions_oppr_1_text"),
      image: ImgOppr1
    },
    {
      title: t("solutions_oppr_2_title"),
      text: t("solutions_oppr_2_text"),
      image: ImgOppr2
    },
    {
      title: t("solutions_oppr_3_title"),
      text: t("solutions_oppr_3_text"),
      image: ImgOppr3
    },
    {
      title: t("solutions_oppr_4_title"),
      text: t("solutions_oppr_4_text"),
      image: ImgOppr4
    },
    {
      title: t("solutions_oppr_5_title"),
      text: t("solutions_oppr_5_text"),
      image: ImgOppr5
    }
  ])

  return (
    <>
      <Nav />
      <WrapperPage>
        <Banner title={t("solutions_title")} />
        <div className="w-full bg-white">
          <WrapperBlock>
            <div className="w-full">
              <div className="mb-40 flex w-full justify-center">
                <img src={Logo} alt="" className="h-80" />
              </div>
              <div className="mb-20 w-full text-center text-20 font-bold text-blue-dark sm:text-24">
                {t("solutions_sub")}
              </div>
              <div className="mb-50 w-full text-center text-14 font-semibold text-blue-dark sm:mb-70 sm:text-16 md:text-18">
                {t("solutions_text")}
              </div>
              <div className="mb-60 flex w-full justify-center sm:mb-80">
                <div className="gris-cols-1 grid w-[900px] max-w-full gap-20 sm:grid-cols-2 sm:gap-30">
                  <button
                    onClick={() => setType(SolutionType.Approach)}
                    className="grid grid-cols-1 rounded-[20px] bg-blue-dark p-20 sm:p-30 md:p-40"
                  >
                    <div className="w-full text-center text-16 font-bold text-white sm:text-18 md:text-24">
                      {t("solutions_appr_title")}
                    </div>
                    <div className="w-full text-center text-14 text-white sm:text-16">
                      {t("solutions_more")}
                    </div>
                  </button>
                  <button
                    onClick={() => setType(SolutionType.Oppurtunities)}
                    className="grid grid-cols-1 rounded-[20px] bg-blue-dark p-20 sm:p-30 md:p-40"
                  >
                    <div className="w-full text-center text-16 font-bold text-white sm:text-18 md:text-24">
                      {t("solutions_oppr_title")}
                    </div>
                    <div className="w-full text-center text-14 text-white sm:text-16">
                      {t("solutions_more")}
                    </div>
                  </button>
                </div>
              </div>
              <div className="grid w-full grid-cols-1 gap-40 sm:gap-60 md:gap-[70px]">
                {
                  {
                    [SolutionType.Approach]: (
                      <>
                        <div className="grid w-full grid-cols-1 gap-10">
                          <div className="w-full text-center text-16 font-bold text-blue-dark sm:text-18 md:text-24">
                            {t("solutions_appr_title")}
                          </div>
                          <div className="w-full text-center text-14 text-blue-dark sm:text-16">
                            {t("solutions_appr_sub")}
                          </div>
                        </div>
                        <div className="grid w-full grid-cols-1 items-start gap-60 lg:grid-cols-2 lg:gap-100">
                          <div className="grid w-full grid-cols-1 items-start gap-40 sm:gap-60">
                            <SolutionsItem item={listAppr[0]} />
                            <SolutionsItem item={listAppr[1]} />
                            <SolutionsItem item={listAppr[2]} />
                            <SolutionsItem item={listAppr[3]} />
                          </div>
                          <div className="grid w-full grid-cols-1 items-start gap-40 sm:gap-60">
                            <SolutionsItem item={listAppr[4]} />
                            <SolutionsItem item={listAppr[5]} />
                            <SolutionsItem item={listAppr[6]} />
                          </div>
                        </div>
                      </>
                    ),
                    [SolutionType.Oppurtunities]: (
                      <>
                        <div className="grid w-full grid-cols-1 gap-10">
                          <div className="w-full text-center text-16 font-bold text-blue-dark sm:text-18 md:text-24">
                            {t("solutions_oppr_title")}
                          </div>
                          <div className="w-full text-center text-14 text-blue-dark sm:text-16">
                            {t("solutions_oppr_sub")}
                          </div>
                        </div>
                        <div className="grid w-full grid-cols-1 items-start gap-60 lg:grid-cols-2 lg:gap-100">
                          <div className="grid w-full grid-cols-1 items-start gap-40 sm:gap-60">
                            <SolutionsItem item={listOppr[0]} />
                            <SolutionsItem item={listOppr[1]} />
                            <SolutionsItem item={listOppr[2]} />
                          </div>
                          <div className="grid w-full grid-cols-1 items-start gap-40 sm:gap-60">
                            <SolutionsItem item={listOppr[3]} />
                            <SolutionsItem item={listOppr[4]} />
                          </div>
                        </div>
                      </>
                    )
                  }[type]
                }
              </div>
            </div>
          </WrapperBlock>
        </div>
      </WrapperPage>
      <Footer />
    </>
  )
}

export default Solutions
