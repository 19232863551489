import { createCtx } from "contexts/Context"
import { getterLanguage, setterLanguage } from "libs/functions/cookies"
import i18n from "libs/i18n"
import React, { createContext, useEffect, useState } from "react"
import { useCookies } from "react-cookie"

type PropsContextType = {
  authenticated: boolean
  language: string
  path: string
  route: boolean
  token: any
  setLanguage: any
  setPath: any
}

export const [useProps, CtxProvider] = createCtx<PropsContextType>()

export const PropsContext = createContext<PropsContextType | undefined>(
  undefined
)

export const PropsProvider = ({ children }: { children: React.ReactNode }) => {
  const [authenticated] = useState(false)
  const [language, setLanguage] = useState(getterLanguage())
  const [path, setPath] = useState(window.location.pathname)
  const [route] = useState(
    parseInt(process.env.REACT_APP_ADMIN ?? "") ? true : false
  )
  const [token] = useCookies(["auth"])

  useEffect(() => {
    console.log(language)

    setterLanguage(language)
    i18n.changeLanguage(language)
  }, [language])

  return (
    <>
      <CtxProvider
        value={{
          authenticated,
          language,
          path,
          route,
          token,
          setLanguage,
          setPath
        }}
      >
        {children}
      </CtxProvider>
    </>
  )
}

export default PropsProvider
