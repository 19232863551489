export const clearStorage = () => {
  localStorage.clear()
}

export const removeStorage = (name: string) => {
  localStorage.removeItem(name)
}

export const getterAuthenticated = () => {
  return localStorage.getItem("y-analytics-authenticated") === "true" || false
}

export const setterAuthenticated = (bool: boolean) => {
  localStorage.setItem("y-analytics-authenticated", `${bool}`)

  return Promise.resolve()
}

export const getterToken = () => {
  return localStorage.getItem("y-analytics-token") || ""
}

export const setterToken = (token: string) => {
  localStorage.setItem("y-analytics-token", token)

  return Promise.resolve()
}

export const getterLanguage = () => {
  return localStorage.getItem("y-analytics-language") || ""
}

export const setterLanguage = (language: string) => {
  localStorage.setItem("y-analytics-language", language)

  return Promise.resolve()
}
