import NavOpen from "components/nav/NavOpen"
import Wrapper from "components/wrapper/Wrapper"
import { useProps } from "contexts/PropsContext"
import { URL } from "libs/constants"
import { LinkInterface } from "libs/interfaces"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { VscThreeBars } from "react-icons/vsc"
import { Link } from "react-router-dom"

const Nav = () => {
  const { language, path, setLanguage } = useProps()

  const { t } = useTranslation(["landing"])

  const [open, setOpen] = useState(false)
  const [top, setTop] = useState(true)

  const [links] = useState<LinkInterface[]>([
    { title: t("nav_home"), link: URL.HOME },
    { title: t("nav_approach"), link: URL.APPROACH },
    { title: t("nav_solution"), link: URL.SOlUTIONS },
    { title: t("nav_about"), link: URL.ABOUT },
    { title: t("nav_cases"), link: URL.CASES },
    { title: t("nav_contact"), link: URL.CONTACT }
  ])

  useEffect(() => {
    window.onscroll = () => {
      setTop(window.pageYOffset === 0)
    }

    return () => {
      window.onscroll = null
    }
  }, [])

  return (
    <>
      <div
        className={`fixed top-0 left-0 z-40 flex w-full justify-center bg-white px-20 transition-all duration-300 sm:px-30 ${
          top ? "h-80 sm:h-[100px]" : "h-80"
        }`}
      >
        <div className="grid w-full max-w-screen-xl grid-cols-[1fr,auto] items-center justify-center gap-30 sm:gap-40 md:gap-60">
          <div className="hidden w-full items-center justify-between gap-10 xl:flex">
            {links.map((link: LinkInterface, key: number) => (
              <Link
                to={link.link}
                key={key}
                className={`outline-none text-16 uppercase text-blue-dark transition-all duration-300 hover:font-black sm:text-18 md:text-20 ${
                  path === link.link ? "font-black" : ""
                }`}
              >
                {link.title}
              </Link>
            ))}
          </div>
          <button
            onClick={() => setOpen(!open)}
            className="flex h-50 w-50 items-center justify-center xl:hidden"
          >
            <VscThreeBars className="text-24 text-blue-dark" />
          </button>
          <div className="flex items-center gap-8">
            <button
              onClick={() => setLanguage("nl")}
              className={`text-14 transition-all duration-300 hover:font-black sm:text-16 md:text-18 ${
                language === "nl" ? "font-black" : ""
              }`}
            >
              {t("nav_nl")}
            </button>
            <div className="h-20 w-2 rounded-full bg-blue-dark"></div>
            <button
              onClick={() => setLanguage("en")}
              className={`text-14 transition-all duration-300 hover:font-black sm:text-16 md:text-18 ${
                language === "en" ? "font-black" : ""
              }`}
            >
              {t("nav_en")}
            </button>
          </div>
        </div>
      </div>
      <Wrapper open={open}>
        <NavOpen />
      </Wrapper>
    </>
  )
}

export default Nav
