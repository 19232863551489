import axios from "axios"
import FooterArea from "components/footer/FooterArea"
import FooterInput from "components/footer/FooterInput"
import WrapperBlock from "components/wrapper/WrapperBlock"
import WrapperBlockSmall from "components/wrapper/WrapperBlockSmall"
import { EMAIL, SOCIAL } from "libs/constants"
import { openLink } from "libs/functions"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { FaInstagram, FaLinkedin } from "react-icons/fa"
import { toast } from "react-toastify"

const Footer = () => {
  const { t } = useTranslation(["landing"])

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  const onSubmit = () => {
    if (!(name && email && phone && message)) {
      toast.error(t("form_complete"))
      return
    }

    axios({
      url: `https://api.elasticemail.com/v2/email/send?apikey=${EMAIL.api}&subject=Y-analytics Contact Form&from=${EMAIL.username}&fromName=Y-analytics Contact&to=${EMAIL.username}&bodyText=New message from ${name}, ${email}, ${phone}: ${message}`,
      method: "post",
      data: {},
      headers: {}
    })
      .then((res) => res.data)
      .then((res: any) => {
        if (res.success) {
          setName("")
          setEmail("")
          setPhone("")
          setMessage("")

          toast.success("Successfully sent your message!")
        } else {
          toast.error("An error occured sending your message!")
        }
      })
      .catch(() => {
        toast.error("An error occured sending your message!")
      })
  }

  return (
    <>
      <div className="w-full bg-grey-40">
        <WrapperBlock wide={true}>
          <div className="grid w-full grid-cols-1 gap-20 sm:gap-30 xl:grid-cols-[1fr,2fr]">
            <div className="w-full rounded-[20px] bg-blue-dark p-20 sm:px-30 sm:pb-30">
              <div className="mb-20 w-full text-18 font-bold text-white sm:text-24 md:text-32">
                {t("footer_contact_title")}
              </div>
              <div className="mb-40 w-full text-16 text-white sm:text-18 md:text-20">
                {t("footer_contact_text")}
              </div>
              <div className="mb-30 grid w-full grid-cols-1 gap-8">
                <div className="w-full text-14 text-white sm:text-16 md:text-18">
                  <span className="text-14 font-bold text-white sm:text-16 md:text-18">
                    {t("footer_contact_phone")}
                  </span>{" "}
                  +31 6 23 54 87 88
                </div>
                <button
                  onClick={(e) => openLink(e, "mailto:welcome@y-analytics.nl")}
                  className="w-full text-left text-14 text-white sm:text-16 md:text-18"
                >
                  <span className="text-14 font-bold text-white sm:text-16 md:text-18">
                    {t("footer_contact_mail")}
                  </span>{" "}
                  welcome@y-analytics.nl
                </button>
              </div>
              <div className="flex w-full items-center justify-start gap-14">
                <button onClick={(e) => openLink(e, SOCIAL.INSTAGRAM)}>
                  <FaInstagram className="text-32 text-white" />
                </button>
                <button onClick={(e) => openLink(e, SOCIAL.LINKEDIN)}>
                  <FaLinkedin className="text-32 text-white" />
                </button>
              </div>
            </div>
            <div className="w-full rounded-[20px] bg-blue-dark p-20 sm:px-30 sm:pb-30">
              <div className="mb-20 w-full text-18 font-bold text-white sm:text-24 md:text-32">
                {t("form_title")}
              </div>
              <div className="mb-20 grid w-full grid-cols-1 gap-20">
                <FooterInput
                  title={t("form_name")}
                  value={name}
                  handler={setName}
                />
                <FooterInput
                  title={t("form_email")}
                  value={email}
                  handler={setEmail}
                />
                <FooterInput
                  title={t("form_phone")}
                  value={phone}
                  handler={setPhone}
                />
                <FooterArea
                  title={t("form_message")}
                  value={message}
                  handler={setMessage}
                />
              </div>
              <div className="flex w-full justify-end">
                <button
                  onClick={() => onSubmit()}
                  className="flex h-50 w-full items-center justify-center bg-purple text-white sm:w-[220px]"
                >
                  {t("form_send")}
                </button>
              </div>
            </div>
          </div>
        </WrapperBlock>
      </div>
      <div className="w-full bg-white">
        <WrapperBlockSmall>
          <div className="flex w-full items-center justify-center gap-20">
            <button
              onClick={(e) => openLink(e, SOCIAL.COOKIE)}
              className="text-18 text-blue-dark"
            >
              Cookie statement
            </button>
            <button
              onClick={(e) => openLink(e, SOCIAL.PRIVACY)}
              className="text-18 text-blue-dark"
            >
              Privacy statement
            </button>
          </div>
        </WrapperBlockSmall>
      </div>
    </>
  )
}

export default Footer
