import { URL } from "libs/constants"
import React from "react"
import { Route } from "react-router-dom"
import About from "views/About"
import Approach from "views/Approach"
import Cases from "views/Cases"
import Contact from "views/Contact"
import Home from "views/Home"
import NotFound from "views/NotFound"
import Solutions from "views/Solutions"

export default [
  {
    component: (
      <Route exact path={URL.HOME} key={0}>
        <Home />
      </Route>
    )
  },
  {
    component: (
      <Route exact path={URL.ABOUT} key={1}>
        <About />
      </Route>
    )
  },
  {
    component: (
      <Route exact path={URL.APPROACH} key={2}>
        <Approach />
      </Route>
    )
  },
  {
    component: (
      <Route exact path={URL.CASES} key={3}>
        <Cases />
      </Route>
    )
  },
  {
    component: (
      <Route exact path={URL.CONTACT} key={4}>
        <Contact />
      </Route>
    )
  },
  {
    component: (
      <Route exact path={URL.SOlUTIONS} key={5}>
        <Solutions />
      </Route>
    )
  },
  {
    component: (
      <Route key={6}>
        <NotFound />
      </Route>
    )
  }
]
