import Nav from "components/nav/Nav"
import React from "react"

const NotFound = () => {
  return (
    <>
      <Nav />
      Not Found
    </>
  )
}

export default NotFound
