import Banner from "assets/img/banner.jpg"
import { ReactComponent as Triangle } from "assets/img/triangle.svg"
import { useInterval } from "libs/functions"
import React, { useState } from "react"

const BannerSlide = ({ titles }: { titles: string[] }) => {
  const [active, setActive] = useState(0)

  useInterval(() => {
    setActive((active + 1) % titles.length)
  }, 4000)

  return (
    <>
      <div className="relative mb-0 w-full bg-gradient-to-tr from-blue-light to-blue sm:mb-60">
        <Triangle className="absolute top-0 right-0 z-10 hidden h-[400px] w-[400px] translate-x-[50%] translate-y-[-40%] rotate-[12deg] transform md:flex" />
        <Triangle className="absolute bottom-0 left-0 z-10 hidden h-[500px] w-[500px] translate-x-[-75%] translate-y-[20%] rotate-[0deg] transform md:flex" />
        <div className="absolute top-0 left-0 h-full w-full">
          <img
            src={Banner}
            alt=""
            className="h-full w-full object-cover opacity-[0.25]"
          />
        </div>
        <div className="relative h-[440px] w-full overflow-hidden p-60 sm:p-80 md:p-[120px]">
          {titles.map((title: string, key: number) => (
            <div
              className={`absolute left-[50%] top-[50%] grid w-full max-w-[60%] translate-x-[-50%] translate-y-[-50%] transform grid-cols-1 gap-20 transition-all duration-300 ${
                active === key ? "opacity-100" : "opacity-0"
              }`}
              key={key}
            >
              <div className="md:48 w-full text-center text-24 font-bold text-blue-dark sm:text-32">
                {title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default BannerSlide
