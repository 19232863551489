import { SolutionInterface } from "libs/interfaces"
import React from "react"

const SolutionsItem = ({ item }: { item: SolutionInterface }) => {
  return (
    <>
      <div className="grid w-full grid-cols-1 gap-14">
        <div className="grid w-full grid-cols-[auto,1fr] items-center gap-20">
          <div className="flex">
            <img
              src={item.image}
              alt=""
              className="mx-[-2px] mt-[-10px] mb-[-6px] h-50"
            />
          </div>
          <div className="w-full text-14 font-bold text-blue-dark sm:text-16 md:text-18">
            {item.title}
          </div>
        </div>
        <div className="w-full text-14 text-blue-dark">{item.text}</div>
      </div>
    </>
  )
}

export default SolutionsItem
