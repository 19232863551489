import React from "react"

const WrapperBlockSmall = ({
  children,
  wide
}: {
  children: any
  wide?: boolean
}) => {
  return (
    <>
      <div className="relative flex w-full justify-center px-20 py-30 sm:px-30 sm:py-40">
        <div
          className={`w-full ${wide ? "max-w-screen-xl" : "max-w-screen-lg"}`}
        >
          {children}
        </div>
      </div>
    </>
  )
}

export default WrapperBlockSmall
